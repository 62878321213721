/* Load missing polyfills - Most are loaded by babel-reset-env */
import "core-js/es/promise";
import "whatwg-fetch";
import "proxy-polyfill";
import smoothscroll from "smoothscroll-polyfill";

import React from "react";
import { render, unmountComponentAtNode } from "react-dom";
import EventEmitter from "eventemitter3";

import { CONFIGS, DEFAULTS } from "CONFIGS";

import __PACKAGE__ from "../package.json";
import {
	getDataAttribute,
	shadowDOM,
	externalStylesheet,
	injectIntoHostingApplication
} from "./utils";

import "./scss/_icons.scss";

class FlightStatusAndAlerts {
	constructor() {
		// Expose client-side library to interact with widget
		window.WEB_CDN_FSA = this;

		this.version = __PACKAGE__.version;
		this.build = process.env.BUILD_ID;
		this.isInitializing = false;
		this.isInitialized = false;
		this._emitter = new EventEmitter();
		this._preinitializedEvents = [];
		this._reactRoot = null;
		this.init();
	}

	handlePreinitializedEvents() {
		while (this._preinitializedEvents.length) {
			const event = this._preinitializedEvents.shift();
			this._emitter.emit(event.eventName, event.payload);
		}
	}

	init() {
		// Set when widget is fully initialized - determined per view
		this._emitter.on("initialized", () => {
			this.isInitialized = true;
			this.handlePreinitializedEvents();
			this.onLoad();
		});

		if (!this.isInitializing || !this.isInitialized) {
			/* Initialize shadow dom */
			const [appDOM, reactRoot, reactDOM] = shadowDOM(__PACKAGE__.name);

			/* Save reference to reactRoot in order to be used to unmount */
			this._reactRoot = reactRoot;

			/* Initialize ScrollTo smooth behaviour polyfill */
			smoothscroll.polyfill();

			/* Retrieve app settings from data attributes / configuration */
			/**
			 * Get language from data attribute or use default
			 * @category App Initialization
			 * @type {string}
			 * @default en
			 */
			const lang = getDataAttribute(appDOM, "lang", DEFAULTS.LANGUAGE);

			/**
			 * Get Infoservice API url from data attribute or use default
			 * @category App Initialization
			 * @type {string}
			 * @default https://infoservice.sunwingtravelgroup.com
			 */
			const infoserviceApi = getDataAttribute(
				appDOM,
				"infoserviceApi",
				INFOSERVICE_API
			);

			/**
			 * Get Logging API url from data attribute or use default
			 * @category App Initialization
			 * @type {string | string}
			 * @default https://weblogging.sunwingtravelgroup.com/api/v1/
			 */
			const loggingApi = getDataAttribute(appDOM, "loggingApi", LOGGING_API);

			const fsaApi = getDataAttribute(appDOM, "fsaApi", FSA_API);
			const alertApi = getDataAttribute(appDOM, "alertApi", ALERT_API);

			/**
			 * Get configuration for enabling Logging API from data attribute or use default
			 * @category App Initialization
			 * @type {string | string}
			 * @default "true"
			 */
			const enableLogging = getDataAttribute(
				appDOM,
				"enableLogging",
				ENABLE_LOGGING
			);

			/**
			 * Get stylesheet override url from data attribute or use default
			 * @category App Initialization
			 * @type {string | undefined}
			 * @default undefined
			 */
			const stylesheet = getDataAttribute(appDOM, "stylesheet", undefined);

			/* Load external stylesheet into shadow dom / dom when supplied */
			injectIntoHostingApplication(
				appDOM,
				"https://fonts.googleapis.com/css?family=PT+Sans:400,700"
			);

			injectIntoHostingApplication(
				appDOM,
				"https://necolas.github.io/normalize.css/latest/normalize.css"
			);
			externalStylesheet(reactDOM, stylesheet);

			CONFIGS.initialize({
				infoserviceApi,
				loggingApi,
				fsaApi,
				alertApi,
				enableLogging
			})
				.then(() => import("./App.js"))
				.then(({ default: App }) => {
					this.isInitializing = false;
					render(
						<App
							lang={lang}
							reactDOM={reactDOM}
							appRoot={reactRoot}
							emitter={this._emitter}
						/>,
						reactRoot
					);
				});
		}

		return this;
	}

	onLoad(callback) {
		if (callback && typeof callback === "function") {
			callback(this);
		}

		return this;
	}

	overrideDefaults(settings) {
		if (!this.isInitialized) {
			this._preinitializedEvents.push({
				eventName: "override-defaults",
				payload: settings
			});
			return;
		}

		try {
			if (this._emitter) {
				this._emitter.emit("override-defaults", settings);
			}
		} catch {
			console.error("WEB.CDN.FSA | Unable to emit event");
		}
	}

	destroy() {
		if (this.isInitialized) {
			try {
				if (unmountComponentAtNode(this._reactRoot)) {
					this.isInitialized = false;
					this._emitter.off("initialized");
					this._emitter.off("update-data-layer");
				} else {
					console.error("WEB.CDN.FSA | Unable to unmount application");
				}
			} catch (error) {
				console.error("WEB.CDN.FSA | Unable to unmount application", error);
			}
		}
	}
}

window.WEB_CDN_FSA = new FlightStatusAndAlerts();
