/* spell-checker: disable */

const DEFAULTS = {
	INFOSERVICE_API: "https://infoservice.sunwingtravelgroup.com",
	LOGGING_API: "https://weblogging.sunwingtravelgroup.com/api/v1/",
	FSA_API: "https://apiuat.sunwingapi.com/product/opn/flights/Status",
	ALERT_API: "https://acapiuat2.sunwingtravelgroup.com",
	ENABLE_LOGGING: true,
	CONTENTFUL_SPACE_RCL: "bjgonf6fglpm",
	RCL_APP_CODE: "FSA",
	LANGUAGE: "en",
	FLIGHT_SEARCH_FROM_DATE: 9,
	FLIGHT_SEARCH_TO_DATE: 28
};

// Configs / API / Endpoints
const CONFIGS = {
	_infoserviceApi: "",
	_loggingApi: "",
	_fsaApi: "",
	_alertApi: "",

	// DCIS
	DCIS_ENDPOINT: "/contentful",

	initialize({ infoserviceApi, loggingApi, fsaApi, alertApi, enableLogging }) {
		this._infoserviceApi = infoserviceApi;
		this._loggingApi = loggingApi;
		this._fsaApi = fsaApi;
		this._alertApi = alertApi;
		this._enableLogging = enableLogging;

		return new Promise(resolve => resolve(this));
	},

	get LOGGER() {
		return {
			API: this._loggingApi,
			ENABLE: this._enableLogging
		};
	},

	get FLIGHTS() {
		return {
			ALL_BY_DATE: (dto, dtfrom, type, lang) =>
				`${this._fsaApi}?dto=${dto}&dtfrom=${dtfrom}&type=${type}&Language=${lang}`,
			CUSTOMER_FORM: `${this._fsaApi}/customer/forms`
		};
	},

	get ALERTS() {
		return {
			CREATE: `${this._alertApi}/api/v2/FlightNotifications`,
			SEARCH: (lang, email, phone) =>
				`${this._alertApi}/api/v2/FlightNotifications?Email=${email}&Mobile=${phone}&Language=${lang}`,
			REMOVE: id => `${this._alertApi}/api/v2/FlightNotifications?AlertID=${id}`
		};
	},

	get DCIS() {
		return this._infoserviceApi + this.DCIS_ENDPOINT;
	}
};

module.exports = {
	DEFAULTS,
	CONFIGS
};
